import http from "../httpConfig"

class ChattingService {
  
  getUsers(params) {
    return http
      .get("/chat_user", { params: params })
      .then((res) => {
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
  }
  
  sendMessage(message) {
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
    return http
      .post(`/chat_message`, message, config)
      .then((res) => {
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
  }
  
  getMessages(params) {
    return http
      .get(`/chat_message`, { params: params })
      .then((res) => {
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
  }
  
  downloadFile(msg) {
    return http
      .get(`/chat_message/${msg._id}/download_file`, { responseType: "blob" })
      .then((res) => {
        this.download(res.data, msg.file)
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
  }
  
  download(data, fileName) {
    let blob = new Blob([data], {
      type: "application/octet-stream",
    })
    let downloadElement = document.createElement("a")
    let href = window.URL.createObjectURL(blob)
    downloadElement.href = href
    downloadElement.download = fileName.split(/__fn__(.+)/)[1]
    document.body.appendChild(downloadElement)
    downloadElement.click()
    document.body.removeChild(downloadElement)
    window.URL.revokeObjectURL(href)
  }
}

export default new ChattingService()
