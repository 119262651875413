import http from "../httpConfig";

class VariantService {
  createVariant(model) {
    return http
      .post("/vehicle/variant", model)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }
  
  updateVariant(model) {
    return http
      .put(`/vehicle/variant/${model._id}`, model)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }
  
  async updateVDetail(_id, model) {
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    
    await http
      .put(`/vehicle/vdetail/${_id}`, model, config)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }
  
  getVariants(params) {
    return http
      .get("/vehicle/variant", { params: params })
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }
  
  getVariant(_id) {
    return http
      .get(`/vehicle/variant/${_id}`)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }
  
  deleteVariant(model) {
    return http
      .delete(`/vehicle/variant/${model._id}`)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }
  
  selectedVariants(params) {
    return http
      .get("/vehicle/variant/selected", { params: params })
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }
  
  completedVariants(params) {
    return http
      .get("/vehicle/variant/completed", { params: params })
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }
  
  regSearch(reg_no) {
    return http
      .get(`/vehicle/reg_search/${reg_no}`)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }
}

export default new VariantService();
