import Vue from "vue";
import VueRouter from "vue-router";
import routes from "./routes";
import store from "../store";

Vue.use(VueRouter);

const router = new VueRouter({
   mode: "history",
   base: process.env.BASE_URL,
   routes,
   linkActiveClass: "active",
});

router.beforeEach(async (to, from, next) => {
   store.commit("route_change", "start");
   if (store.state.loggedIn) {
      store
         .dispatch("refreshUserData")
         .then((user) => {
            next();
         })
         .catch((err) => {
            store.dispatch("logout");
            next("/login");
         });
   } else {
      next();
   }
});

router.afterEach((to, from) => {
   document.title = to.meta.title;
   store.commit("route_change", "end");
   store.commit("route_name", to.name);
   if (window.innerWidth < 992) {
      store.commit("left_menu", "close");
   } else {
      store.commit("left_menu", "open");
   }
});

export default router;
