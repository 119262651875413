import { render, staticRenderFns } from "./pre-loader.vue?vue&type=template&id=246f3a6f&scoped=true&"
import script from "./pre-loader.js?vue&type=script&lang=js&"
export * from "./pre-loader.js?vue&type=script&lang=js&"
import style0 from "./pre-loader.scss?vue&type=style&index=0&id=246f3a6f&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "246f3a6f",
  null
  
)

export default component.exports