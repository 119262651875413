import http from "../httpConfig";

class TicketMessageService {
   sendMessage(id, message) {
      const config = {
         headers: {
            "Content-Type": "multipart/form-data",
         },
      };
      return http
         .post(`/ticket_message/${id}`, message, config)
         .then((res) => {
            return Promise.resolve(res.data);
         })
         .catch((err) => {
            return Promise.reject(err);
         });
   }

   getMessages(id, params) {
      return http
         .get(`/ticket_messages/${id}`, { params: params })
         .then((res) => {
            return Promise.resolve(res.data);
         })
         .catch((err) => {
            return Promise.reject(err);
         });
   }

   deleteMessage(id) {
      return http
         .delete(`/ticket_message/${id}`)
         .then((res) => {
            return Promise.resolve(res.data);
         })
         .catch((err) => {
            return Promise.reject(err);
         });
   }

   downloadMsgFile(ticket, msg) {
      return http
         .get(`/ticket_message/${ticket._id}/${msg._id}/download_file`, { responseType: "blob" })
         .then((res) => {
            this.download(res.data, msg.file);
            return Promise.resolve(res.data);
         })
         .catch((err) => {
            return Promise.reject(err);
         });
   }

   download(data, fileName) {
      let blob = new Blob([data], {
         type: "application/octet-stream",
      });
      let downloadElement = document.createElement("a");
      let href = window.URL.createObjectURL(blob);
      downloadElement.href = href;
      downloadElement.download = fileName.split(/__fn__(.+)/)[1];
      document.body.appendChild(downloadElement);
      downloadElement.click();
      document.body.removeChild(downloadElement);
      window.URL.revokeObjectURL(href);
   }
}

export default new TicketMessageService();
